(function(d,t) {
    var BASE_URL="https://nxticket.com.br";
    var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
    g.src=BASE_URL+"/packs/js/sdk.js";
    g.defer = true;
    g.async = true;
    s.parentNode.insertBefore(g,s);
    g.onload=function(){
      window.chatwootSDK.run({
        websiteToken: 'uyf8Nde63bcznQXNmeieL3Yj',
        baseUrl: BASE_URL
      })
    }
  })(document,"script");